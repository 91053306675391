import React from 'react';
import { NavLink } from 'react-router-dom';
import './navigation.styles.scss';

const Navigation = ({menuIsOpen, toggleMenu}) => (
    <nav className={menuIsOpen ? `open` : ``}>  
        <ul>
            <li className="background-color__second-color">
                <span className="color__third-color uppercase">
                    <NavLink to='/leistungen' activeClassName='selected' onClick={toggleMenu}>
                        Unser<br/> Angebot
                    </NavLink>
                </span>
            </li>
            <li className="background-color__fourth-color">
                <span className="color__third-color uppercase">
                    <NavLink to='/praxis' activeClassName='selected' onClick={toggleMenu}>
                        Die<br/> Praxis
                    </NavLink>
                </span>
            </li>
            <li className="background-color__first-color">
                <span className="color__third-color uppercase">
                    <NavLink to='/team' activeClassName='selected' onClick={toggleMenu}>
                        Das<br/> Team
                    </NavLink>
                </span>
            </li>
            <li className="background-color__second-color">
                <span className="color__third-color uppercase">
                    <NavLink to='/kontakt' activeClassName='selected' onClick={toggleMenu}>
                        Kontakt
                    </NavLink>
                </span>
            </li>
        </ul>
        <div className="nav-lightbox"></div>
    </nav>
);

export default Navigation;