import React from 'react';
import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';
import HeaderDummy from './components/header/header-dummy/header-dummy.component';
import Startpage from './sites/startpage/startpage.site';
import Surgery from './sites/surgery/surgery.site';
import Contact from './sites/contact/contact.site';
import Services from './sites/services/services.site';
import Team from './sites/team/team.site';
import News from './sites/news/news.site';
import NoMatchPage from './sites/404/404.component';
import ServicesArticles from './sites/services-article/services-article.site';
import { Route, Switch } from 'react-router-dom';
import { stopAnimationOnResize } from './base.scripts/functions';
import 'normalize.css/normalize.css';
import './base.styles/App.base.styles.scss';

const API_EMPLOYEES = 'https://ergo-baumheier-admin.herokuapp.com/mitarbeiters';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
        DATA_EMPLOYEES: [],
        API_SERVICES: []
    };
  }
  componentDidMount() {
    stopAnimationOnResize();

    fetch(API_EMPLOYEES)
    .then(response => response.json())
    .then(data => this.setState( {DATA_EMPLOYEES: data }));
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <HeaderDummy />
        <Switch>
          <Route
            path='/'
            render={() => <Startpage />}
            exact
          />
          <Route
          path='/leistungen'
          render={() => <Services />}
          exact
          />
          <Route path="/leistungen/:id" component={ServicesArticles} />
          <Route
            path='/praxis'
            render={() => <Surgery />}
          />
          <Route
            path='/team'
            render={() => <Team DATA_EMPLOYEES={this.state.DATA_EMPLOYEES} />}
          />
          <Route
            path='/aktuelles'
            render={() => <News />}
          />
          <Route
          path='/kontakt'
          render={() => <Contact />}
          />
          <Route path="*" component={NoMatchPage} />
          </Switch>
          <Footer />
      </React.Fragment>
    );
  }

}
