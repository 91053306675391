import React from 'react';
import './trainings.styling.scss';

const Trainings = ({id, isOpen, EMPLOYEE}) => (
    <div className={`trainings-wrapper ${isOpen ? 'open' : ''}`}>
        <div className="trainings" id={id}>
            <div className="block-1x1-small">
            <div className="square background-color__fourth-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__third-color">Fortbildungen</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            { EMPLOYEE.fortbildungen_trainings.map((trainings, index) => (
                <div key={index} className="block-1x1-small">
                    <div className="square">
                        <div className="square__content flex-center color__fourth-color uppercase font__regular inner-text">
                            <div className="max-width-70">
                                {trainings.Bezeichnung}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

export default Trainings;