import React from 'react';
import Head from '../../components/head-standart/head-standart.component';
import pic_404 from '../../assets/404_pic.svg';
import './404.styles.scss';

const NoMatchPage = () => (
    <main>
        <Head
            url="/404"
            title={ `404 nicht gefunden - Ergotherapie Soest`}
            desc="404 - Diese Seite wurde leider nicht gefunden..."
            sitename={ `404 nicht gefunden - Ergotherapeutische Praxis Annegret Baumheier`}
        />
        <div className="nomatch">
            <img src={pic_404} alt="404! Diese Seite wurde leider nicht gefunden!"/>
        </div>
    </main>
)

export default NoMatchPage;