import React from 'react';
import './btn-menu.styles.scss'

const MenuButton = ({toggleMenu, isMobile, menuIsOpen}) => (
    <div
        className={`btn-menu ${menuIsOpen ? `open` : ``}`}
        onClick={toggleMenu}
        >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
);

export default MenuButton;