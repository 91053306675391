import React from 'react';
import { Helmet } from "react-helmet";
import fbImg from '../../assets/fb_social.png';
import twImg from '../../assets/twitter_social.png';

const Head = (props) => (
    <React.Fragment>
    <Helmet>
        <meta property="og:title" content={props.title} />
        <meta property="og:image" content={fbImg} />
        <meta property="og:url" content={`https://ergobaumheier.de${props.url}`} />
        <meta property="og:image:secure_url" content={props.imgURL} />
        <meta property="og:site_name" content={props.sitename} />
        <meta property="og:description" content={props.desc} />
        <meta name="twitter:title" content={props.title} />
        <meta name="twitter:description" content={props.desc} />
        <meta name="twitter:image" content={twImg} />
        <meta name="description" content={props.desc} />
        <link rel="canonical" href={`https://ergobaumheier.de${props.url}`} />
        <title>{props.title}</title>
    </Helmet>
    </React.Fragment>
    )

export default Head;