import React, { Component } from 'react';
import Head from '../../components/head-standart/head-standart.component';
import spinner from '../../assets/loading_spinner.svg';

const API_POSTS = 'https://ergo-baumheier-admin.herokuapp.com/neuigkeiten-posts';

export default class News extends Component {
    constructor() {
        super()

        this.state = {
            posts: [],
            isLoading: true
        }
    }
    componentDidMount() {
        this.fetchPosts();
    }
    fetchPosts = () => {
        fetch(API_POSTS)
        .then(response => response.json())
        .then(DATA => this.setState({posts: DATA, isLoading: false}));
    }
    reformatDate(date) {
        const day = date.slice(8);
        const month = date.slice(5,7);
        const year = date.slice(0,4);
        return `${day}.${month}.${year}`;
    }
    bgColorCounter = (count) => {
        switch (count) {
            case 0:
                count++;
                return 'fourth';
            case 1:
                count++;
                return 'second';
            case 2:
                count = 0;
                return 'first';                
            default:
                return 'fourth';
        }
    }
    render() {
        let count = -1;
        if (this.state.isLoading === true)  {
            return (
                <main className="isLoading">
                    <Head
                        url="/aktuelles"
                        title="Aktuelles aus der Ergotherapeutische Praxis Annegret Baumheier"
                        desc="Unsere Neuigkeiten - Jakobistraße 41-43 - Entwicklung und Rehabilitation Annegret Baumheier"
                        sitename="Das gibt es neues in der Ergotherapeutische Praxis Annegret Baumheier"
                    />
                    <img src={spinner} alt="Inhalt wird geladen..."/>
                </main>
            )
        } else {
            return (
                
                <main>
                    <Head
                        url="https://www.ergobaumheier.de/aktuelles/"
                        title="Aktuelles aus der Ergotherapeutische Praxis Annegret Baumheier"
                        desc="Unsere Neuigkeiten - Jakobistraße 41-43 - Entwicklung und Rehabilitation Annegret Baumheier"
                        sitename="Das gibt es neues in der Ergotherapeutische Praxis Annegret Baumheier"
                    />
                
                    <section className="one-one-news">
                        {this.state.posts.map((post, index) => {
                            count++
                            return (
                                <div key={index} className="block-1x1">
                                    <div className={`square background-color__${this.bgColorCounter(count)}-color square__grow__mobile`}>
                                        <div className="square__content square__content__grow__mobile flex-center mobile__no-align">
                                            <div className="max-width-70 ">
                                                <h2 className="color__third-color"><span className="font__regular">{this.reformatDate(post.Erstellungsdatum)}</span><br></br><span className="heading__big__upper">{post.Titel}</span></h2>
                                                    <p className="color__third-color">{post.Text}</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}      
                    </section>
                </main>
            )
        }
    }
}