import React, {Component} from 'react';
import Trainings from './trainings/trainings.component';
import './employee-card.styles.scss';

export default class EmployeeCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        }
    }
    toggleTrainings = (e) => {
        this.setState({isOpen: !this.state.isOpen});
        if (!this.state.isOpen) {
            const scrollHeight = document.querySelectorAll('.block-1x1-small')[0].offsetHeight;
            window.scrollBy(0, scrollHeight);
        }
    }

    openInfos = (e) => {
        e.target
            .closest('.block-2x2')
            .querySelector('.team-more-info')
            .classList.add('team-more-info__is-open');
    }
    closeInfos = (e) => {
        e.target
            .closest('.team-more-info')
            .classList.remove('team-more-info__is-open');
    }
    render() {
        const {index, EMPLOYEE} = this.props;
        if (index % 2 === 0) {
            return (
                <section className="overflow__hidden">
                    <div className="one-four">
                        <div className="block-1x1 hide__mobile">
                            <div className="square background-color__third-color">
                                <div className="square__content">
                                    <div className="square__picture" style={ {backgroundImage: `url('${EMPLOYEE.Foto.url}')`} }></div>
                                </div>
                            </div>
                        </div>
                        <div className="block-2x2 position-relative">
                            <div className="square background-color__second-color">
                                <div className="square__content flex-center">
                                    <div className="max-width-70">
                                        <h2 className="team-name-line-height "><span className="heading__small color__fourth-color">{EMPLOYEE.Beruf}</span><br /><span className="heading__normal color__third-color">{EMPLOYEE.Vorname}<br/>{EMPLOYEE.Nachname}</span></h2>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="square background-color__third-color">
                                <div className="square__content">
                                    <div className="flex-center square__picture team_yellow" style={ {backgroundImage: `url('${EMPLOYEE.Foto.url}')`} }>
                                        <div>
                                            <div className="heading__big color__first-color heading__big__fix hide__mobile">
                                            {EMPLOYEE.Beschreibung === "asdf" ? "" : <span className="cursor__pointer" onClick={this.openInfos}>info</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="square background-color__second-color">
                                <div className="square__content">
                                {EMPLOYEE.fortbildungen_trainings.length > 0 ? (
                                    <div className="content__inner__wrapper team__toggle" onClick={this.toggleTrainings}>
                                        <div>
                                            <div className="heading__big color__first-color heading__big__fix">
                                                <i className={`icon__plus ${this.state.isOpen ? '' : 'icon__active'}`}></i>
                                                <i className={`icon__minus ${this.state.isOpen ? 'icon__active' : ''}`}></i>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="content__inner__wrapper">
                                        <div>
                                        <div className="heading__big color__first-color heading__big__fix">
                                            </div>
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>
                            <div className="square background-color__second-color">
                                <div className="square__content flex-center">
                                    <div className="max-width-70 team__noTransition">
                                        {EMPLOYEE.Fachgebiet_eins || EMPLOYEE.Fachgebiet_zwei || EMPLOYEE.Fachgebiet_drei ? <span className="heading__normal color__fourth-color specials">Fachgebiete</span> : ""}
                                        {EMPLOYEE.Fachgebiet_eins ? <div className="button__small buttom__team color__third-color">{EMPLOYEE.Fachgebiet_eins}</div> : ""}
                                        {EMPLOYEE.Fachgebiet_zwei ? <div className="button__small buttom__team color__third-color">{EMPLOYEE.Fachgebiet_zwei}</div> : ""}
                                        {EMPLOYEE.Fachgebiet_drei ? <div className="button__small buttom__team color__third-color">{EMPLOYEE.Fachgebiet_drei}</div> : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="team-more-info position-absolute hide__mobile background-color__second-color">
                                <div className="square">
                                    <div className="square__content flex-center mobile__no-align">
                                        <div className="max-width-80 position-relative">
                                            <p className="color__third-color">{EMPLOYEE.Beschreibung}</p>
                                            <p className="team__info-close color__first-color cursor__pointer" onClick={this.closeInfos}>Schließen!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                
                    <Trainings isOpen={this.state.isOpen} EMPLOYEE={EMPLOYEE} />
                </section>
            );
        }
        else {
            return (
                <section className="overflow__hidden">
                    <div className="four-one">
                    <div className="block-2x2 position-relative">
                    <div className="square background-color__first-color">
                        <div className="square__content flex-center">
                            <div className="max-width-70">
                                <h2 className="team-name-line-height "><span className="heading__small color__second-color">{EMPLOYEE.Beruf}</span><br /><span className="heading__normal color__third-color">{EMPLOYEE.Vorname}<br/>{EMPLOYEE.Nachname}</span></h2>                                        
                            </div>
                        </div>
                    </div>
                        <div className="square background-color__third-color">
                            <div className="square__content">
                                <div className="flex-center square__picture team_green" style={ {backgroundImage: `url('${EMPLOYEE.Foto.url}')`} }>
                                    <div>
                                        <div className="heading__big color__second-color heading__big__fix hide__mobile">
                                            {EMPLOYEE.Beschreibung === "asdf" ? "" : <span className="cursor__pointer" onClick={this.openInfos}>info</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div className="square background-color__first-color">
                        <div className="square__content">
                        {EMPLOYEE.fortbildungen_trainings.length > 0 ? (
                            <div className="content__inner__wrapper team__toggle" onClick={this.toggleTrainings}>
                                <div>
                                    <div className="heading__big color__second-color heading__big__fix">
                                        <i className={`icon__plus ${this.state.isOpen ? '' : 'icon__active'}`}></i>
                                        <i className={`icon__minus ${this.state.isOpen ? 'icon__active' : ''}`}></i>
                                    </div>
                                </div>
                            </div>
                    ) : (
                            <div className="content__inner__wrapper">
                                <div>
                                    <div className="heading__big color__second-color heading__big__fix">
                                    </div>
                                </div>
                            </div>
                    )}
                        </div>
                    </div>
                    <div className="square background-color__first-color">
                        <div className="square__content flex-center">
                            <div className="max-width-70 team__noTransition">
                                {EMPLOYEE.Fachgebiet_eins || EMPLOYEE.Fachgebiet_zwei || EMPLOYEE.Fachgebiet_drei ? <span className="heading__normal color__second-color specials">Fachgebiete</span> : ""}
                                {EMPLOYEE.Fachgebiet_eins ? <div className="button__small buttom__team color__third-color">{EMPLOYEE.Fachgebiet_eins}</div> : ""}
                                {EMPLOYEE.Fachgebiet_zwei ? <div className="button__small buttom__team color__third-color">{EMPLOYEE.Fachgebiet_zwei}</div> : ""}
                                {EMPLOYEE.Fachgebiet_drei ? <div className="button__small buttom__team color__third-color">{EMPLOYEE.Fachgebiet_drei} </div> : ""}
                            </div>
                        </div>
                    </div>
                            <div className="team-more-info position-absolute hide__mobile background-color__first-color">
                                <div className="square">
                                    <div className="square__content flex-center mobile__no-align">
                                        <div className="max-width-80 position-relative">
                                            <p className="color__third-color">{EMPLOYEE.Beschreibung}</p>
                                            <p className="team__info-close color__second-color cursor__pointer" onClick={this.closeInfos}>Schließen!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
                        <div className="block-1x1 hide__mobile">
                            <div className="square background-color__third-color">
                                <div className="square__content">
                                    <div className="square__picture" style={ {backgroundImage: `url('${EMPLOYEE.Foto.url}')`} }></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Trainings isOpen={this.state.isOpen} EMPLOYEE={EMPLOYEE} />
                </section>
            );
        }
    }
}