import React from 'react';
import { NavLink } from 'react-router-dom';
import MenuButton from './btn-menu/btn-menu.component';
import Navigation from './navigation/navigation.component'
import './header.styles.scss';
import logo from '../../assets/logo.svg';

export default class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuIsOpen: false
        };
    }
    checkWidth() {
        return window.innerWidth < 960;
    }
    toggleMenu = (e) => {
        if (this.checkWidth() && e.target.classList.contains('logo-data') && this.state.menuIsOpen) {
            this.setState({menuIsOpen: !this.state.menuIsOpen});
        } else if (this.checkWidth() && !e.target.classList.contains('logo-data')) {
            this.setState({menuIsOpen: !this.state.menuIsOpen});
        }
    }
    render() {
        return (
            <header className="background-color__first-color">
                <NavLink to='/' className="logo" onClick={this.toggleMenu}>
                    <img src={logo} className="logo-data" alt="Ergotherapie Soest - Annegereth Baumheier" />
                </NavLink>
                <Navigation
                    menuIsOpen={this.state.menuIsOpen}
                    toggleMenu={this.toggleMenu}
                />
                <MenuButton
                    menuIsOpen={this.state.menuIsOpen}
                    toggleMenu={this.toggleMenu}
                    />
            </header>
        )
    }
}