import React from 'react';
import Head from '../../components/head-standart/head-standart.component';
import './team.styles.scss';
import EmployeeCard from '../../components/employee-card/employee-card.component';
import Gruppenfoto from '../../assets/gruppenfoto.jpg';

const Team = ({DATA_EMPLOYEES}) => {
    
    const DATA = DATA_EMPLOYEES;
    const justActiveDATA = DATA.filter(el => el.Aktiv)
    justActiveDATA.sort((a, b) => {
        if (a.Nachname < b.Nachname) {
            return -1;
        } 
        if (a.Nachname > b.Nachname) {
            return 1;
        }
        return 0;
    })

    justActiveDATA.sort((a, b) => {
        if (a.Beruf < b.Beruf) {
            return 1;
        } 
        if (a.Beruf > b.Beruf) {
            return -1;
        }
        return 0;
    })

    return (
        <main>
            <Head
                url="/team"
                title="Das Team der Ergotherapeutische Praxis Annegret Baumheier"
                desc="Unsere Therapeuten - Jakobistraße 41-43 - Entwicklung und Rehabilitation Annegret Baumheier"
                sitename="Therapeuten kennenlernen - Ergotherapeutische Praxis Annegret Baumheier"
            />
            {justActiveDATA.map((EMPLOYEE, index) => (
                <EmployeeCard key={index} index={index} EMPLOYEE={EMPLOYEE} />
            ))}
            <section>
                <img src={Gruppenfoto} style={{width: '100%', height: 'auto'}} alt="Ergotherapie Soest Annegreth Baumheier Team"/>
            </section>
        </main>
)};

export default Team;