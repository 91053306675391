import React from 'react';
import { Link } from 'react-router-dom';
import './footer.styles.scss';
import logo from '../../assets/logo.svg';
import icon_telephone from '../../assets/icon_telephone_white.svg';
import icon_house from '../../assets/icon_house_white.svg';
import icon_email from '../../assets/icon_email_white.svg';

const Footer = () => (
    <footer>
        <div className="footer">
            <div className="description">
                <div className="footer__logo">
                    <img src={logo} className="footer__logo-self" alt="Ergotherapeutische Praxis Soest - Annegereth Baumheier" />
                </div>
                <p>
                    Wir möchten, dass Sie sich bei uns wohl und gut aufgehoben fühlen. Dafür haben wir unseren Räumen in der Ergotherapeutischen Praxis Annegret Baumheier in Soest eine angenehme, offene Atmosphäre gegeben.
                </p>
                    <ul className="footer__contact">
                        <li>
                            <img src={icon_telephone} className="icon" alt="Rufen Sie uns an." /><br/>
                            02921 / 663501
                        </li>
                        <li>
                            <img src={icon_house} className="icon" alt="Rufen Sie uns an." /><br/>
                            Jakobistr. 41-43<br />
                            59494 Soest
                        </li>
                        <li>
                            <img src={icon_email} className="icon" alt="Rufen Sie uns an." /><br/>
                            info@ergobaumheier.de
                        </li>
                    </ul>
            </div>
            <div className="links">
                <ul>
                    <li>Menü</li>
                    <li><Link to="/">Startseite</Link></li>
                    <li><Link to="/leistungen">Unser Angebot</Link></li>
                    <li><Link to="/praxis">Die Praxis</Link></li>
                    <li><Link to="/team">Das Team</Link></li>
                    <li><Link to="/kontakt">Kontakt</Link></li>
                </ul>
                <ul>
                    <li>Links</li>
                    <li><a rel="noopener noreferrer" target="_blank" href="https://www.dve.info">Berufsverband</a></li>
                    <li><a rel="noopener noreferrer" target="_blank" href="https://www.marburgerkonzentrationstraining.de">MKT</a></li>
                    <li><a rel="noopener noreferrer" target="_blank" href="https://www.ivrt.de">ivrt.de</a></li>
                    <li><a rel="noopener noreferrer" target="_blank" href="https://cptplank.io">cptPLANK.io Webdesign</a></li>
                </ul>
            </div>
        </div>
        <div className="footer__copyright">
            <span>© 2020, Ergotherapeutische Praxis Annegret Baumheier - <a href="/leistungen/impressum">Impressum</a> - <a href="/leistungen/datenschutz">Datenschutz</a> - <a style={{cursor: 'pointer'}} class="ch2-open-settings-btn">Cookies</a></span>
        </div>
    </footer>
);

export default Footer;