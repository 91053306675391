import React from 'react';
import { Link } from 'react-router-dom';
import Head from '../../components/head-standart/head-standart.component';
import './startpage.styles.scss';
import icon_telephone from '../../assets/icon_telephone.svg';
import icon_house from '../../assets/icon_house.svg';
import icon_email from '../../assets/icon_email.svg';
import pic_titelbild from '../../assets/titelbild.jpg';
import pic_startpage_ergotherapieSoest from '../../assets/startpage_ergotherapie-soest.jpg';
import pic_46062292 from '../../assets/46062292.jpg';
import pic_teamErgopraxisSoestStartseite from '../../assets/team-ergopraxis-soest-startseite.jpg';
import pic_178215257 from '../../assets/178215257.jpg';
import pic_kognitives_training from '../../assets/kognitives_training.jpg';
import pic_91230016 from '../../assets/91230016.jpg';
import pic_kontakt from '../../assets/kontakt-ergotherapie-soest-annegret-baumheier-praxis.jpg';
import pic_144833630 from '../../assets/144833630.jpg';
import pic_eingangsbereich_sw from '../../assets/eingangsbereich-sw.jpg';
import pic_hirnleistungsTraining from '../../assets/hirnleistungsTraining.jpg';
import pic_startseiteEingangsBereich from '../../assets/startseiteEingangsBereich_Thomaestrasse.jpg';
import pic_zertifiziert_ergopraxis_soest_baumheier from '../../assets/zertifiziert_ergopraxis_soest_baumheier.jpg';


const Startpage = () => (
    <main>
        <Head
            url="/"
            title="Ergotherapeutische Praxis Annegret Baumheier"
            desc="Ergotherapeutische Praxis in Soest - Jakobistraße 41-43 - Entwicklung und Rehabilitation Annegret Baumheier"
            sitename="Ergotherapie Soest - Ergotherapeutische Praxis Annegret Baumheier"
        />
        <section className="four-one">
            <div className="block-2x2">
                <div className="square background-color__second-color">
                    <div className="square__content">
                        <div className="content__inner__wrapper">
                            <div>
                                <h1 className="heading__big color__third-color heading__big__fix">Leben<br />Qualität<br />geben</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="square background-color__third-color">
                    <div className="square__content flex-center">
                        <div className="max-width-75">
                            <div className="flex-start-center contact_square"><img src={icon_telephone} className="icon" alt="Rufen Sie uns an." /><a href="tel:00492921663501" className="no_underline font__heavy color__first-color">02921 / 663501</a></div>
                            <div className="flex-start-center contact_square"><img src={icon_house} className="icon" alt="Adresse Ergotherapeutische Praxis Annegret Baumheier." /><span className="font__heavy color__fourth-color">Jakobistr. 41-43<br></br>59494 Soest</span></div>
                            <div className="flex-start-center"><img src={icon_email} className="icon" alt="Schreiben Sie uns eine E-Mail." /><a href="mailto:info@ergobaumheier.de" className="no_underline font__heavy color__second-color">info@ergobaumheier.de</a></div>
                        </div>
                    </div>
                </div>
                <div className="square background-color__fourth-color">
                    <div className="square__content">
                        <div className="square__picture" style={ {backgroundImage: `url('${pic_eingangsbereich_sw}')`} }></div>
                    </div>
                </div>
                <Link to="/leistungen/ergotherapie-handlungs-kompetenz-erlangen-und-erhalten" className="square background-color__first-color">
                    <div className="square__content flex-center">
                        <div className="max-width-70">
                            <h2><span className="heading__small color__second-color">Das ist</span><br /><span className="heading__normal color__third-color">Ergotherapie</span></h2>
                                <p className="hide__mobile color__third-color">Ergotherapie ist ein wichtiger Bestandteil einer ganzheitlichen Behandlung.</p>
                            <div className="button__small color__third-color">Kennenlernen</div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="block-1x1 hide__mobile">
                <div className="square background-color__fourth-color">
                    <div className="square__content">
                    <div className="square__picture" style={ {backgroundImage: `url('${pic_titelbild}')`} }></div>
                    </div>
                </div>
            </div>
        </section>
        <section className="two-one-two">
        <div className="block-1x2">
            <Link to="/leistungen/marburger-konzentrations-training" className="square background-color__third-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__first-color">Marburger</span><br />
                                <span className="color__fourth-color">Konzentrations</span><br />
                                <span className="color__second-color">Training</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </Link>
            <div className="square background-color__thrid-color">
                <div className="square__content">
                <div className="square__picture" style={ {backgroundImage: `url('${pic_startseiteEingangsBereich}')`} }></div>
                </div>
            </div>
        </div>
        <div className="block-1x1">
            <Link to="/leistungen" className="square background-color__third-color no_underline square__grow__mobile square__picture__background"  style={ {backgroundImage: `url('${pic_144833630}')`} }>
                <div className="square__content square__content__grow__mobile flex-center mobile__no-align">
                    <div className="max-width-80">
                        <h2><span className="heading__big__upper color__second-color">So hilft</span><br /><span className="heading__big color__first-color">Ergotherapie</span></h2>
                            <p className="color__fourth-color">Das Ziel von Ergotherapie ist es Handlungskompetenz zu erlangen oder wiederzuerlangen, die durch eine Krankheit verloren gegangen oder aus unterschiedlichsten Gründen noch nicht voll entwickelt worden sind.</p>
                        <div className="button__yellow color__second-color">Angebote</div>
                    </div>
                </div>
            </Link>
        </div>
        <div className="block-1x2">

                        <div className="square background-color__second-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                            <span className="color__third-color">Feinmotorik &</span><br />
                            <span className="color__third-color">Handkraft</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="square background-color__fourth-color">
                <div className="square__content">
                    <div className="square__picture" style={ {backgroundImage: `url('${pic_zertifiziert_ergopraxis_soest_baumheier}')`} }></div>
                </div>
            </div>
            
        </div>
    </section>

    <section className="one-one-one-one">
        <div className="block-1x1-small">
            <Link to="/team" className="square background-color__fourth-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__third-color">Therapeuten</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
        <div className="block-1x1-small">
            <div className="square background-color__third-color">
                <div className="square__content">
                    <div className="square__picture" style={ {backgroundImage: `url('${pic_teamErgopraxisSoestStartseite}')`} }></div>
                </div>
            </div>
        </div>
        <div className="block-1x1-small">
            <div className="square background-color__third-color">
                <div className="square__content">
                    <div className="square__picture" style={ {backgroundImage: `url('${pic_hirnleistungsTraining}')`} }></div>
                </div>
            </div>
        </div>
        <div className="block-1x1-small">
            <Link to="/praxis" className="square background-color__third-color">
                <div className="square__content flex-center">
                    <div className="max-width-70">
                        <h2><span className="heading__small color__second-color">Praxis</span><br /><span className="heading__normal color__first-color">Rundgang</span></h2>
                            <p className="hide__mobile color__fourth-color">Es ist uns wichtig Ihnen eine angenehme Athmosphäre zu schaffen. Hier bekommen Sie einen Eindruck von unseren Räumlichkeiten.</p>
                        <div className="button__yellow color__second-color">Ansehen</div>
                    </div>
                </div>
            </Link>
        </div>
    </section>

    <section className="one-one">
        <div className="block-1x1">
            <div className="square background-color__third-color">
                <div className="square__content">
                    <div className="square__picture" style={ {backgroundImage: `url('${pic_kognitives_training}')`} }></div>
                </div>
            </div>
        </div>
        <div className="block-1x1">
            <Link to="/leistungen/kognitives-training-fur-erwachsene" className="square background-color__third-color no_underline square__grow__mobile square__picture__background"  style={ {backgroundImage: `url('${pic_91230016}')`} }>
                <div className="square__content square__content__grow__mobile flex-center mobile__no-align">
                    <div className="max-width-80">
                        <h2><span className="heading__big__upper color__third-color">Kognitives</span><br /><span className="heading__big color__third-color">Training</span></h2>
                            <p className="color__third-color">Ein gezieltes Training kognitiver Funktionen schafft Lebensqualität und verbessert die Selbständigkeit auch im hohen Lebensalter.</p>
                        <div className="button__small color__third-color">Mitmachen!</div>
                    </div>
                </div>
            </Link>
        </div>
    </section>

    <section className="one-one-one-one">
        <div className="block-1x1-small">

            <Link to="/leistungen/hirnleistungs-training-in-der-gruppe"  className="square background-color__third-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__fourth-color">Zertifiziertes</span><br />
                                <span className="color__first-color">Hirnleistungs</span><br />
                                <span className="color__first-color">Training</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </Link>

        </div>
        <div className="block-1x1-small">
            
            <Link to="/leistungen/fur-ausprobierer-mini-tummeltreff-in-der-jakobistrasse" className="square background-color__second-color">
            <div className="square__content flex-center">
            <div className="max-width-70">
                <h2><span className="heading__small color__third-color">Mini</span><br /><span className="heading__normal color__first-color">Tummeltreff</span></h2>
                <p className="hide__mobile color__fourth-color">Es ist nie zu früh Bewegungserfahrungen zu sammeln.</p>
                <div className="button__small color__third-color">Mitmachen!</div>
            </div>
        </div>
            </Link>
        </div>
        <div className="block-1x1-small">
            <div className="square background-color__third-color">
                <div className="square__content">
                   <div className="square__picture" style={ {backgroundImage: `url('${pic_kontakt}')`} }></div>
                </div>
            </div>
        </div>
        <div className="block-1x1-small">
            <Link to="/kontakt" className="square background-color__fourth-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__third-color">Kontakt</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    </section>
    </main>
);

export default Startpage;