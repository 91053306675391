import React from 'react';
import { Link } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import Head from '../../components/head-standart/head-standart.component';
import './services-article.style.scss';
import slugify from 'slugify';
import NoMatchPage from '../404/404.component'
import spinner from '../../assets/loading_spinner.svg';

const API_SERVICES = 'https://ergo-baumheier-admin.herokuapp.com/leistungen-services';

export default class ServicesArticles extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            API_SERVICES: [],
            slugExists: null,
            FINAL_DATA: [],
            TEXT_DATA: ' ',
            TITLE: ' '
        }
    }
    componentDidMount() {               
        this.fetchData();
    }
    fetchData = () => {
        fetch(API_SERVICES)
        .then(response => response.json())
        .then(data => this.setState( {API_SERVICES: data }))
        .then(() => {
            if (this.checkSlug().length !== 0) {
                this.setState( {slugExists: true });                
                this.setState( {FINAL_DATA: this.checkSlug() });
                this.setState( {isLoading: false });
                this.setState( {TEXT_DATA: this.seperateMarkdownText() });
                this.setState( {TITLE: this.state.FINAL_DATA.map(data => data.Titel)[0] })
            } else {
                this.setState( {slugExists: false });
            }
        });
    }
    checkSlug = () => {
        const param = this.props.match.params.id;
        return this.state.API_SERVICES.filter(item => slugify(item.Titel, {remove: /[*+~.()'"!:@,]/g}).toLowerCase() === param);
    }
    seperateMarkdownText = () => {
        return this.state.FINAL_DATA.map(data => data.Leistung);
    }
    render() {
        const slug = slugify(this.state.TITLE, {remove: /[*+~.()'"!:@,]/g}).toLowerCase();
        console.log(slug);

        if (this.state.slugExists === false) {
            return (
                    <NoMatchPage />
            )
        } else if (this.state.slugExists === null)  {
            return (
                <main className="isLoading">
                    <Head
                        url={`/leistungen/${ slug }`}
                        title={ `${this.state.TITLE} - Ergotherapie Soest`}
                        desc={this.state.FINAL_DATA.map(data => data.SEO_Meta_Description)}
                        sitename={ `${this.state.TITLE} - Ergotherapeutische Praxis Annegret Baumheier`}
                    />
                    <img src={spinner} alt="Inhalt wird geladen..."/>
                </main>
            )
        } else {
            return (
                <main>
                    <Head
                    url={`/leistungen/${ slug }`}
                    title={ `${this.state.TITLE} - Ergotherapie Soest`}
                    desc={this.state.FINAL_DATA.map(data => data.SEO_Meta_Description)}
                    sitename={ `${this.state.TITLE} - Ergotherapeutische Praxis Annegret Baumheier`}
                    />
                <section className="blog-grid">
                <div className="order-to-end__mobile">
                    <div className="block-1xn">
                        <div className="square background-color__fourth-color">
                            <div className="square__content">
                                {this.state.FINAL_DATA.map((pic, index) => (
                                    <div key={index} className="square__picture" style={ {backgroundImage: `url('${pic.Artikelbild.url}')`} }></div>
                                ))}
                            </div>
                        </div>
                        <Link to="/leistungen/ergotherapie-in-der-orthopadie-rheumatologie-und-traumatologie" onClick={this.fetchData} className="square background-color__third-color">
                            <div className="square__content">
                                <div className="content__inner__wrapper">
                                    <div>
                                        <h2 className="heading__normal heading__normal__fix">
                                            <span className="color__fourth-color">Orthopädie</span><br />
                                            <span className="color__fourth-color">Rheuma- &</span><br />
                                            <span className="color__fourth-color">Traumatologie</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/leistungen/hirnleistungs-training-in-der-gruppe" onClick={this.fetchData} className="square background-color__first-color">
                            <div className="square__content">
                                <div className="content__inner__wrapper">
                                    <div>
                                        <h2 className="heading__normal heading__normal__fix">
                                            <span className="color__second-color">Zertifiziertes</span><br />
                                            <span className="color__third-color">Hirnleistungs</span><br />
                                            <span className="color__third-color">Training</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/leistungen/ergotherapie-in-der-neurologie" onClick={this.fetchData} className="square background-color__second-color">
                            <div className="square__content">
                                <div className="content__inner__wrapper">
                                    <div>
                                        <h2 className="heading__normal heading__normal__fix">
                                            <span className="color__third-color">Neurologie</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>

                        <Link to="/leistungen/ergotherapie-in-der-padiatrie-kinder-und-jugend-psychiatrie" onClick={this.fetchData} className="square background-color__third-color">
                            <div className="square__content">
                                <div className="content__inner__wrapper">
                                    <div>
                                        <h2 className="heading__normal heading__normal__fix">
                                            <span className="color__first-color">Pädiatrie</span><br />
                                            <span className="color__first-color">Kinder- &</span><br />
                                            <span className="color__first-color">Jugendpsychiatrie</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/leistungen/ergotherapie-in-der-geriatrie-geronto-psychiatrie" onClick={this.fetchData} className="square background-color__first-color">
                            <div className="square__content">
                                <div className="content__inner__wrapper">
                                    <div>
                                        <h2 className="heading__normal heading__normal__fix">
                                            <span className="color__second-color">Geriatrie</span> <span className="color__third-color">&</span><br />
                                            <span className="color__second-color">Geronto-</span><br />
                                            <span className="color__second-color">psychiatrie</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/leistungen/ergotherapie-in-der-psychiatrie" onClick={this.fetchData} className="square background-color__fourth-color">
                            <div className="square__content">
                                <div className="content__inner__wrapper">
                                    <div>
                                        <h2 className="heading__normal heading__normal__fix">
                                            <span className="color__third-color">Psychiatrie</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/leistungen/marburger-konzentrations-training" onClick={this.fetchData} className="square background-color__third-color">
                            <div className="square__content">
                                <div className="content__inner__wrapper">
                                    <div>
                                        <h2 className="heading__normal heading__normal__fix">
                                            <span className="color__first-color">Marburger</span><br />
                                            <span className="color__fourth-color">Konzentrations</span><br />
                                            <span className="color__second-color">Training</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <article className="order-to-start__mobile">
                    <h1 className="header__regular uppercase color__second-color">{this.state.FINAL_DATA.map(data => data.Titel)}</h1>
                    <div className="color__fourth-color p__class">
                        <Markdown>
                            {this.state.TEXT_DATA[0]}
                        </Markdown>
                    </div>
                </article>
                <div>
                    <div className="block-1xn">
                        <Link to="/team" className="square background-color__fourth-color">
                            <div className="square__content">
                                <div className="content__inner__wrapper">
                                    <div>
                                        <h2 className="heading__normal heading__normal__fix">
                                            <span className="color__third-color">Therapeuten</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        {this.state.FINAL_DATA.map(data => (
                            data.mitarbeiter_employees.map((employee, index) => (
                                <div key={index} className="square background-color__fourth-color" title={`${employee.Vorname} ${employee.Nachname}`}>
                                    <div className="square__content">
                                        <div className="square__picture" style={ {backgroundImage: `url('${employee.Foto.url}')`} }>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ))}
                        <Link to="/leistungen/ergotherapie-handlungs-kompetenz-erlangen-und-erhalten" onClick={this.fetchData} className="square background-color__second-color">
                            <div className="square__content">
                                <div className="content__inner__wrapper">
                                    <div>
                                        <h2 className="heading__normal heading__normal__fix">
                                            <span className="color__fourth-color">Was ist</span><br />
                                            <span className="color__fourth-color">Ergotherapie</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/leistungen/kognitives-training-fur-erwachsene" onClick={this.fetchData} className="square background-color__third-color">
                            <div className="square__content">
                                <div className="content__inner__wrapper">
                                    <div>
                                        <h2 className="heading__normal heading__normal__fix">
                                            <span className="color__first-color">Kongitives<br />Training</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/leistungen/fur-ausprobierer-mini-tummeltreff-in-der-jakobistrasse" onClick={this.fetchData} className="square background-color__first-color">
                            <div className="square__content">
                                <div className="content__inner__wrapper">
                                    <div>
                                        <h2 className="heading__normal heading__normal__fix">
                                            <span className="color__second-color">Mini</span><br />
                                            <span className="color__third-color">Tummel</span><br />
                                            <span className="color__second-color">Treff</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/kontakt" onClick={this.fetchData} className="square background-color__fourth-color">
                            <div className="square__content">
                                <div className="content__inner__wrapper">
                                    <div>
                                        <h2 className="heading__normal heading__normal__fix">
                                            <span className="color__third-color">Kontakt</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>
            </main>
            )
        }
    }
}


