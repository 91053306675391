import React, { Component } from 'react';
import Head from '../../components/head-standart/head-standart.component';
import './contact.styles.scss';
import emailjs from 'emailjs-com';
import dummy_pic from '../../assets/dummy_pic.jpg';
import ReCAPTCHA from "react-google-recaptcha";
import icon_telephone from '../../assets/icon_telephone.svg';
import icon_house_white from '../../assets/icon_house_white.svg';
import icon_email from '../../assets/icon_email.svg';
import icon_fax from '../../assets/icon_fax.svg';
import map_pic from '../../assets/dummy_pic.svg';
import eingang_vorne from '../../assets/praxiseingang_vorne.png';
import eingang_hinten_eins from '../../assets/praxiseingang_hinten_1.png';
import eingang_hinten_zwei from '../../assets/praxiseingang_hinten_2.png';

export default class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: 0,
            height: 0,
            captchaSuccess: null,
            siteWidth: 375,
            formError: false
        }
    }
    componentDidMount = () => {
        window.addEventListener('resize', this.sizeListener);
        this.sizeListener();
    }
    sizeListener = () => {
        this.setState({width: this.getWidth(), height: this.getHeight(), siteWidth: this.getSiteWidth()});
    }
    getWidth = () => {
        return document.querySelectorAll('.block-1x1')[0].offsetWidth;
    }
    getHeight = () => {
        return document.querySelectorAll('.block-1x1')[0].offsetHeight;
    }
    getSiteWidth = () => {
        return window.innerWidth;
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.sizeListener);
    }
    sendEmail = (e) => {        
        e.preventDefault();
        this.setState({formError: false});
        const reqName = document.querySelector('.input_userName');
        const reqMail = document.querySelector('.input_userMail');
        const reqMsg = document.querySelector('.input_textarea');
        reqName.classList.remove('formError');
        reqMail.classList.remove('formError');
        reqMsg.classList.remove('formError');
        if (!reqName.value) {
            reqName.classList.add('formError');
            this.setState({formError: true});
        }
        
        if (!reqMail.value) {
            reqMail.classList.add('formError');
            this.setState({formError: true});
        }

        if (!reqMsg.value) {
            reqMsg.classList.add('formError');
            this.setState({formError: true});
        }
        
        if (this.state.formError === false) {
            if (this.state.captchaSuccess === true) {
                document.querySelector('.form__button').value = `Wird gesendet...`;
                emailjs.sendForm('no-reply-aeosys-cloud', 'template_NehM19Cu', e.target, 'user_q34rmdNaAJ7U6ybY4zuHv')
                .then((result) => {
                    document.getElementById('checkMail').innerHTML = `<p>Ihr Nachricht wurde erfolgreich gesendet! Vielen Dank für Ihre Nachricht.</p>`;
                    document.getElementById('change-bg').classList.add('green__bg');
                    
                }, (error) => {
                    document.getElementById('checkMail').innerHTML = `<p>Da ist irgendetwas schief gelaufen! Bitte versuchen Sie es später nochmal!</p>`;
                    document.getElementById('change-bg').classList.add('red__bg');
                });
            } else {
                this.setState({captchaSuccess: false});
            }
        }
    }
    captcha = (value) => {
        if (value.length === 0 ) {
            alert('Sie sind ein Roboter!');
        }
        else {
            this.setState({captchaSuccess: true});
        }
    }
    render() {
        return (
            <main id="contact">
            <Head
                url="/kontakt"
                title="Kontaktieren Sie uns! 02921 / 663501 - Ergotherapeutische Praxis Annegret Baumheier"
                desc="Kontaktieren Sie uns! 02921 / 663501 - Jakobistraße 41-43 - Entwicklung und Rehabilitation Annegret Baumheier"
                sitename="Kontaktieren Sie uns - 02921 / 663501 - Ergotherapeutische Praxis Annegret Baumheier"
            />
            <section className="four-one">
            <div className="block-2x2">
                <div className="square background-color__second-color">
                    <div className="square__content">
                        <div className="content__inner__wrapper">
                            <div>
                                <h1 className="heading__big color__third-color heading__big__fix">Kontakt</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="square background-color__third-color">
                    <div className="square__content flex-center">
                        <div className="max-width-70">
                            <div className="flex-mid-center contact_square"><img src={icon_telephone} className="icon__contact flex__fix" alt="Rufen Sie uns an." /><span className="font__heavy flex__fix color__first-color font__contact">02921 / 663501</span></div>
                            <div className="flex-mid-center contact_square"><img src={icon_fax} className="icon__contact flex__fix" alt="Rufen Sie uns an." /><span className="font__heavy flex__fix color__first-color font__contact">02921 / 663502</span></div>
                            
                        </div>
                    </div>
                </div>
                <div className="square background-color__fourth-color">
                <div className="square__content flex-center">
                <div className="max-width-70">
                    <div className="flex-mid-center contact_square"><img src={icon_house_white} className="icon__contact flex__fix" alt="Rufen Sie uns an." /><span className="font__heavy flex__fix color__third-color font__contact">Jakobistr. 41-43<br />59494 Soest</span></div>
                    
                </div>
            </div>
                </div>
                <div className="square background-color__first-color">
                <div className="square__content flex-center">
                <div className="max-width-70">
                    <div className="flex-mid-center contact_square"><img src={icon_email} className="icon__contact flex__fix" alt="Rufen Sie uns an." /><span className="font__heavy flex__fix color__second-color font__contact">info@ergobaumheier.de</span></div>
                    
                </div>
            </div>
                </div>
            </div>
            <div className="block-1x1 hide__mobile">
                <div className="square background-color__second-color">
                    <div className="square__content">
                    <div className="square__picture" style={ {backgroundImage: `url('${map_pic}')`} }>
                    <div><iframe title="Praxis Adresse Google Maps" width={this.state.width} height={this.state.height} src="https://maps.google.com/maps?hl=de&q=ergobaumheier%20soest+&ie=UTF8&t=&z=16&iwloc=B&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">Praxis Adresse Google Maps</iframe></div>
            
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="one-one-one-one">
<div className="block-1x1-small">
    <div className="square background-color__third-color">
        <div className="square__content">
        <div className="square__picture" style={ {backgroundImage: `url('${eingang_hinten_eins}')`} }></div>
        </div>
    </div>
</div>
<div className="block-1x1-small">
    <div className="square background-color__third-color">
        <div className="square__content">
        <div className="square__picture" style={ {backgroundImage: `url('${eingang_hinten_zwei}')`} }></div>
        </div>
    </div>
</div>
<div className="block-1x1-small">
    <div className="square background-color__first-color">
        <div className="square__content flex-center color__third-color uppercase font__regular inner-text"><span className="max-width-70">Sie erreichen die praxiseigenen Parkplätze und den rückwärtigen Eingang über die Kesselstraße.</span></div>
    </div>
</div>
<div className="block-1x1-small">
    <div className="square background-color__third-color">
        <div className="square__content">
        <div className="square__picture" style={ {backgroundImage: `url('${eingang_vorne}')`} }></div>
        </div>
    </div>
</div>
</section>
        <section className="one-one">
        <div className="block-1x1">
        <div className="square background-color__third-color square__grow__mobile ">
        <div className="square__content square__content__grow__mobile">
            <div className="content__inner__wrapper">
                <div className="contact__padding">
                    <h2 className="heading__big color__fourth-color heading__big__fix">Kontakt<br />formular</h2>
                </div>
            </div>
        </div>
    </div>
        </div>
        <div className="block-1x1">
            <div id="change-bg" className="square background-color__second-color square__grow__mobile square__picture__background">
                <div className="square__content square__content__grow__mobile flex-center mobile__no-align">
                    <div id="checkMail" className="max-width-80">
                       <div>
                            <form className="contact-form" onSubmit={this.sendEmail} >
                                <input type="text" className="input_userName" name="user_name" placeholder="Ihr Name *" />
                                <input type="text" name="user_firstname" placeholder="Ihr Vorname" />
                                <input type="number" name="user_tel"  placeholder="Ihre Telefonnummer"  />
                                <input type="text" className="input_userMail" name="user_email"  placeholder="Ihre E-Mail-Adresse *"/>
                                <textarea className="input_textarea" name="message" cols="30" rows="10" placeholder="Ihre Nachricht an uns...  *"></textarea>
                                {this.state.captchaSuccess === false && <p className="p__error">Bitte ReCaptcha bestätigen!</p>}
                                {this.state.formError === true && <p className="p__error">Bitte Eingaben überprüfen!</p>}
                                <input type="submit" value="Absenden" className="form__button" />
                                <p className="p__priv">Sie erklären sich damit einverstanden, dass Ihre Daten zur Bearbeitung gemäß unserer Datenschutzbestimmungen verwendet werden.</p>
                                <ReCAPTCHA
                                    sitekey="6LcQj6kZAAAAACWDdgpjlISC9w6MbKiQRFCu1WTl"
                                    onChange={this.captcha}
                                    size={this.state.siteWidth < 375 ? 'compact' : 'normal'}
                                />
                            </form>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </main>
        )
    }
}