import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import ScrollToTop from './components/scroll-to-top/scroll-to-top.component';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import BrowserDetection from 'react-browser-detection';

const browserHandler = {
  ie: () => <div>
    Ergotherapeutische Praxis Annegret Baumheier<br /><br />
    Jakobistr. 41-43
    59494 Soest<br /><br />

    Tel.02921 / 66350 1<br />
    Fax.02921 / 66350 2<br /><br />

    info@ergobaumheier.de<br /><br />
    ######################<br />
    Herzlich Willkommen auf unserer Website.<br />
    Damit sie unsere attraktive Website betrachten können, bitten wir Sie auf einen modernen Browser umzusteigen oder JavaScript zu aktivieren.<br /><br />
    Wir freuen uns auf Ihren Besuch!
    ######################
    </div>,
  default: () =>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
};

ReactDOM.render(
  <BrowserDetection>
    { browserHandler }
  </BrowserDetection>,
  document.getElementById('root')
);