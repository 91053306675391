import React from 'react';
import { Link } from 'react-router-dom';
import Head from '../../components/head-standart/head-standart.component';
import './services.styles.scss';
import dummy_pic from '../../assets/dummy_pic.jpg';
import pic_eingang from '../../assets/eingangsbereich_2.jpg';
import pic_hirn_2 from '../../assets/hirn_2.jpg';
import pic_psychiatrie from '../../assets/psychiatrie.jpg';
import pic_feinmotorik from '../../assets/feinmotorik.jpg';
import pic_geriatrie from '../../assets/geriatrie.jpg';

const Services = ({employees}) => (
    <main>
        <Head
        url="/leistungen"
        title="Unsere Angebote - Ergotherapeutische Praxis Annegret Baumheier"
        desc="Gerne behandeln wir Sie in unserer Soester Praxis in unterschiedlichen Fachbereichen. Ergotherapeutische Praxis in Soest - Jakobistraße 41-43 - 02921 / 663501"
        sitename="Leistungen & Angebote - Ergotherapeutische Praxis Annegret Baumheier"
        />
        <section className="four-one">
            <div className="block-2x2">
                <div className="square background-color__second-color">
                    <div className="square__content">
                        <div className="content__inner__wrapper">
                            <div>
                                <h1 className="heading__big color__third-color heading__big__fix">Unser<br />Angebot</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="square background-color__fourth-color">
                    <div className="square__content">
                        <div className="square__picture" style={ {backgroundImage: `url('${pic_hirn_2}')`} }></div>
                    </div>
                </div>
                <Link to="/leistungen/marburger-konzentrations-training" className="square background-color__third-color">
                    <div className="square__content">
                        <div className="content__inner__wrapper">
                            <div>
                                <h2 className="heading__normal heading__normal__fix">
                                    <span className="color__first-color">Marburger</span><br />
                                    <span className="color__fourth-color">Konzentrations</span><br />
                                    <span className="color__second-color">Training</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link to="/leistungen/hirnleistungs-training-in-der-gruppe" className="square background-color__first-color">
                    <div className="square__content">
                        <div className="content__inner__wrapper">
                            <div>
                                <h2 className="heading__normal heading__normal__fix">
                                    <span className="color__second-color">Zertifiziertes</span><br />
                                    <span className="color__third-color">Hirnleistungs</span><br />
                                    <span className="color__third-color">Training</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="block-1x1">
                <div className="square background-color__second-color">
                    <div className="square__content">
                        <div className="content__inner__wrapper">
                            <div>
                                <h2 className="heading__normal heading__normal__fix">
                                    <span className="color__third-color">Ergotherapie unterstützt
                                    </span><br />
                                    <span className="color__third-color">Menschen jeden Alters dabei,
                                    </span><br />
                                    <span className="color__third-color">ihre Handlungskompetenz
                                    </span><br />
                                    <span className="color__third-color">zu erlangen und zu erhalten.</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <section className="one-one-one-one">
        <div className="block-1x1-small">
            <Link to="/leistungen/ergotherapie-in-der-neurologie" className="square background-color__second-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__third-color">Neurologie</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
        <div className="block-1x1-small">
        <Link to="/leistungen/ergotherapie-in-der-orthopadie-rheumatologie-und-traumatologie" className="square background-color__third-color">
            <div className="square__content">
                <div className="content__inner__wrapper">
                    <div>
                        <h2 className="heading__normal heading__normal__fix">
                            <span className="color__fourth-color">Orthopädie</span><br />
                            <span className="color__fourth-color">Rheuma- &</span><br />
                            <span className="color__fourth-color">Traumatologie</span>
                        </h2>
                    </div>
                </div>
            </div>
        </Link>
        </div>
        <div className="block-1x1-small">
            <div className="square background-color__third-color">
                <div className="square__content">
                    <div className="square__picture" style={ {backgroundImage: `url('${pic_psychiatrie}')`} }></div>
                </div>
            </div>
        </div>
        <div className="block-1x1-small">
        <Link to="/leistungen/ergotherapie-in-der-psychiatrie" className="square background-color__fourth-color">
            <div className="square__content">
                <div className="content__inner__wrapper">
                    <div>
                        <h2 className="heading__normal heading__normal__fix">
                            <span className="color__third-color">Psychiatrie</span>
                        </h2>
                    </div>
                </div>
            </div>
        </Link>
        </div>
    </section>
    <section className="one-four">
        <div className="block-1x1">
            <div className="square background-color__fourth-color">
                <div className="square__content">
                    <div className="square__picture" style={ {backgroundImage: `url('${pic_geriatrie}')`} }></div>
                </div>
            </div>
        </div>
    <div className="block-2x2">
    <Link to="/leistungen/ergotherapie-in-der-geriatrie-geronto-psychiatrie" className="square background-color__first-color">
        <div className="square__content">
            <div className="content__inner__wrapper">
                <div>
                    <h2 className="heading__normal heading__normal__fix">
                        <span className="color__second-color">Geriatrie</span> <span className="color__third-color">&</span><br />
                        <span className="color__second-color">Geronto-</span><br />
                        <span className="color__second-color">psychiatrie</span>
                    </h2>
                </div>
            </div>
        </div>
    </Link>
        <div className="square background-color__fourth-color">
            <div className="square__content">
                <div className="square__picture" style={ {backgroundImage: `url('${pic_feinmotorik}')`} }></div>
            </div>
        </div>
        <Link to="/leistungen/ergotherapie-in-der-padiatrie-kinder-und-jugend-psychiatrie" className="square background-color__third-color">
            <div className="square__content">
                <div className="content__inner__wrapper">
                    <div>
                        <h2 className="heading__normal heading__normal__fix">
                            <span className="color__first-color">Pädiatrie</span><br />
                            <span className="color__first-color">Kinder- &</span><br />
                            <span className="color__first-color">Jugendpsychiatrie</span>
                        </h2>
                    </div>
                </div>
            </div>
        </Link>
        <div className="square background-color__second-color">
            <div className="square__content">
                <div className="content__inner__wrapper">
                    <div>
                        <h2 className="heading__normal heading__normal__fix">
                            <span className="color__third-color">Feinmotorik &</span><br />
                            <span className="color__third-color">Handkraft</span>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className="one-one-one-one">
<div className="block-1x1-small">
    <Link to="/leistungen/kognitives-training-fur-erwachsene" className="square background-color__second-color">
        <div className="square__content">
            <div className="content__inner__wrapper">
                <div>
                    <h2 className="heading__normal heading__normal__fix">
                        <span className="color__third-color">Kognitives<br />Training</span>
                    </h2>
                </div>
            </div>
        </div>
    </Link>
</div>
<div className="block-1x1-small">
<Link to="/leistungen/vestibulares-training" className="square background-color__third-color">
    <div className="square__content">
        <div className="content__inner__wrapper">
            <div>
                <h2 className="heading__normal heading__normal__fix">
                    <span className="color__fourth-color">Vestibuläre<br />Rehabilitation</span>
                </h2>
            </div>
        </div>
    </div>
</Link>
</div>
<div className="block-1x1-small">
    <div className="square background-color__third-color">
        <div className="square__content">
            <div className="square__picture" style={ {backgroundImage: `url('${pic_eingang}')`} }></div>
        </div>
    </div>
</div>
<div className="block-1x1-small">
<Link to="/kontakt" className="square background-color__fourth-color">
    <div className="square__content">
        <div className="content__inner__wrapper">
            <div>
                <h2 className="heading__normal heading__normal__fix">
                    <span className="color__third-color">Kontakt</span>
                </h2>
            </div>
        </div>
    </div>
</Link>
</div>
</section>
    </main>
);

export default Services;