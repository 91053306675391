import React from 'react';
import Head from '../../components/head-standart/head-standart.component';
import dummy_pic from '../../assets/dummy_pic.jpg';
import pic_eingangsbereich from '../../assets/eingangsbereich.jpg';
import pic_eingang_vorne from '../../assets/eingang_vorne.jpg';
import pic_kontakt from '../../assets/kontakt-ergotherapie-soest-annegret-baumheier-praxis.jpg';
import pic_turnhalle from '../../assets/turnhalle.jpg';
import pic_tummeln from '../../assets/tummeln.jpg';
import pic_integration from '../../assets/sensorische-integration.jpg';
import pic_neuro_ortho from '../../assets/raum_neuro_ortho.jpg';
import pic_handtherapie from '../../assets/handtherapie.jpg';
import pic_neurologie from '../../assets/neurologie.jpg';
import pic_raum_hirn from '../../assets/raum_hirn.jpg';
import pic_hirn_2 from '../../assets/hirn_2.jpg';
import pic_konzentration from '../../assets/konzentration.jpg';
import pic_werkraum from '../../assets/werkraum.jpg';
import pic_werkraum_2 from '../../assets/werkraum_2.jpg';
import pic_werkraum_3 from '../../assets/werkraum_3.jpg';
import { Link } from 'react-router-dom';


const Surgery = () => (
    <main>
    <Head
        url="/praxis"
        title="Die Ergotherapeutische Praxis Annegret Baumheier in Bildern"
        desc="Erkunden Sie unsere Praxis - Jakobistraße 41-43 - Entwicklung und Rehabilitation Annegret Baumheier"
        sitename="Praxis erkunden - Ergotherapeutische Praxis Annegret Baumheier"
    />
    <section className="two-one-two">
        <div className="block-1x2">
        <div className="square background-color__second-color">
            <div className="square__content">
                <div className="content__inner__wrapper">
                    <div>
                        <h2 className="heading__normal heading__normal__fix">
                            <span className="color__third-color">Hereinspaziert</span><br />
                            <span className="color__first-color">in unseren</span><br />
                            <span className="color__first-color">Empfangsbereich</span>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        <div className="square background-color__third-color">
            <div className="square__content">
                <div className="content__inner__wrapper">
                    <div>
                        <h2 className="heading__normal heading__normal__fix">
                            <span className="color__first-color">Moderne,</span><br />
                            <span className="color__fourth-color">freundliche</span><br />
                            <span className="color__second-color">Praxis</span>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className="block-1x1">
        <div className="square background-color__fourth-color">
        <div className="square__content">
            <div className="square__picture" style={ {backgroundImage: `url('${pic_eingangsbereich}')`} }></div>
        </div>
    </div>
        </div>
        <div className="block-1x2">
            <div className="square background-color__third-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__second-color">barrierefrei</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="square background-color__first-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__third-color">Eingänge:</span><br />
                                <span className="color__second-color">Jakobistraße</span><br />
                                <span className="color__third-color">Kesselstraße</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="one-one-one-one">
        <div className="block-1x1-small">
        <div className="square background-color__fourth-color">
        <div className="square__content">
            <div className="square__picture" style={ {backgroundImage: `url('${pic_eingang_vorne}')`} }></div>
        </div>
    </div>
        </div>
        <div className="block-1x1-small">
            <div className="square background-color__fourth-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__third-color">Phantasievolle</span><br />
                                <span className="color__third-color">kindgerechte</span><br />
                                <span className="color__third-color">Spielecke</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="block-1x1-small">
            <div className="square background-color__third-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                            <span className="color__first-color">Hauseigener</span><br />
                            <span className="color__fourth-color">Parkplatz</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="block-1x1-small">
        <div className="square background-color__fourth-color">
        <div className="square__content">
            <div className="square__picture" style={ {backgroundImage: `url('${pic_kontakt}')`} }></div>
        </div>
    </div>
        </div>
    </section>

    <section className="two-one-two">
        <div className="block-1x2">
            <div className="square background-color__third-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__fourth-color">Motorikhalle</span><br />
                                <span className="color__second-color">Vortragsraum</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <Link className="square background-color__first-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__third-color">Sensorische</span><br />
                                <span className="color__third-color">integrations</span><br />
                                <span className="color__second-color">Therapie</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
        <div className="block-1x1">
        <div className="square background-color__fourth-color">
        <div className="square__content">
            <div className="square__picture" style={ {backgroundImage: `url('${pic_turnhalle}')`} }></div>
        </div>
    </div>
        </div>
        <div className="block-1x2">
            <Link to="/leistungen/vestibulares-training" className="square background-color__second-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__fourth-color">Vestibuläre</span><br />
                                <span className="color__fourth-color">Rehabilitation</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </Link>
            <div className="square background-color__third-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__first-color">Motorische</span><br />
                                <span className="color__second-color">Entwicklung</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="one-one-one-one">
        <div className="block-1x1-small">
        <div className="square background-color__fourth-color">
        <div className="square__content">
            <div className="square__picture" style={ {backgroundImage: `url('${pic_tummeln}')`} }></div>
        </div>
    </div>
        </div>
        <div className="block-1x1-small">
            <Link to="/leistungen/fur-ausprobierer-mini-tummeltreff-in-der-jakobistrasse" className="square background-color__third-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__first-color">Mini</span><br />
                                <span className="color__fourth-color">Tummeltreff</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
        <div className="block-1x1-small">
            <div className="square background-color__fourth-color">
                <div className="square__content">
                    <div className="content__inner__wrapper">
                        <div>
                            <h2 className="heading__normal heading__normal__fix">
                                <span className="color__third-color">Wahrnehmungs</span><br />
                                <span className="color__third-color">förderung</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="block-1x1-small">
        <div className="square background-color__fourth-color">
        <div className="square__content">
            <div className="square__picture" style={ {backgroundImage: `url('${pic_integration}')`} }></div>
        </div>
    </div>
        </div>
    </section>

    <section className="two-one-two">
    <div className="block-1x2">
    <div className="square background-color__third-color">
        <div className="square__content">
            <div className="content__inner__wrapper">
                <div>
                    <h2 className="heading__normal heading__normal__fix">
                        <span className="color__first-color">Räume für</span><br />
                        <span className="color__fourth-color">Neurologie &</span><br />
                        <span className="color__second-color">Orthopädie</span>
                    </h2>
                </div>
            </div>
        </div>
    </div>
    <div className="square background-color__first-color">
        <div className="square__content">
            <div className="content__inner__wrapper">
                <div>
                    <h2 className="heading__normal heading__normal__fix">
                        <span className="color__third-color">Handtherapie</span>
                    </h2>
                </div>
            </div>
        </div>
    </div>
    </div>
    <div className="block-1x1">
    <div className="square background-color__fourth-color">
    <div className="square__content">
        <div className="square__picture" style={ {backgroundImage: `url('${pic_neuro_ortho}')`} }></div>
    </div>
</div>
    </div>
    <div className="block-1x2">
        <div className="square background-color__second-color">
            <div className="square__content">
                <div className="content__inner__wrapper">
                    <div>
                        <h2 className="heading__normal heading__normal__fix">
                            <span className="color__first-color">Bobath</span><br />
                            <span className="color__third-color">Konzept</span>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        <div className="square background-color__third-color">
            <div className="square__content">
                <div className="content__inner__wrapper">
                    <div>
                        <h2 className="heading__normal heading__normal__fix">
                            <span className="color__first-color">Gleichgewichts</span><br />
                            <span className="color__second-color">Übungen</span>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className="one-one-one-one">
    <div className="block-1x1-small">
        <div className="square background-color__third-color">
            <div className="square__content">
            <div className="square__picture" style={ {backgroundImage: `url('${pic_handtherapie}')`} }></div>
            </div>
        </div>
    </div>
    <div className="block-1x1-small">
        <div className="square background-color__third-color">
            <div className="square__content">
                <div className="content__inner__wrapper">
                    <div>
                        <h2 className="heading__normal heading__normal__fix">
                            <span className="color__second-color">Entspannungs</span><br />
                            <span className="color__second-color">techniken</span>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="block-1x1-small">
        <div className="square background-color__fourth-color">
            <div className="square__content">
                <div className="content__inner__wrapper">
                    <div>
                        <h2 className="heading__normal heading__normal__fix">
                            <span className="color__third-color">Gang- &</span><br />
                            <span className="color__third-color">Koordinations</span><br />
                            <span className="color__third-color">Training</span>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="block-1x1-small">
        <div className="square background-color__third-color">
            <div className="square__content">
            <div className="square__picture" style={ {backgroundImage: `url('${pic_neurologie}')`} }></div>
            </div>
        </div>
    </div>
</section>

<section className="two-one-two">
<div className="block-1x2">
<div className="square background-color__second-color">
    <div className="square__content">
        <div className="content__inner__wrapper">
            <div>
                <h2 className="heading__normal heading__normal__fix">
                    <span className="color__first-color">Raum für</span><br />
                    <span className="color__first-color">Hinrleistungs</span><br />
                    <span className="color__first-color">Training</span>
                </h2>
            </div>
        </div>
    </div>
</div>
<Link to="/leistungen/marburger-konzentrations-training" className="square background-color__third-color">
    <div className="square__content">
        <div className="content__inner__wrapper">
            <div>
                <h2 className="heading__normal heading__normal__fix">
                    <span className="color__first-color">Marburger</span><br />
                    <span className="color__fourth-color">Konzentrations</span><br />
                    <span className="color__second-color">Training</span>
                </h2>
            </div>
        </div>
    </div>
</Link>
</div>
<div className="block-1x1">
<div className="square background-color__fourth-color">
<div className="square__content">
    <div className="square__picture" style={ {backgroundImage: `url('${pic_raum_hirn}')`} }></div>
</div>
</div>
</div>
<div className="block-1x2">
    <div className="square background-color__third-color">
        <div className="square__content">
            <div className="content__inner__wrapper">
                <div>
                    <h2 className="heading__normal heading__normal__fix">
                        <span className="color__fourth-color">PC-Arbeitsplatz</span>
                    </h2>
                </div>
            </div>
        </div>
    </div>
    <div className="square background-color__first-color">
        <div className="square__content">
            <div className="content__inner__wrapper">
                <div>
                    <h2 className="heading__normal heading__normal__fix">
                        <span className="color__second-color">COGPACK</span><br />
                        <span className="color__third-color">Freshminder</span>
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<section className="one-one-one-one">
<div className="block-1x1-small">
    <div className="square background-color__third-color">
        <div className="square__content">
        <div className="square__picture" style={ {backgroundImage: `url('${pic_hirn_2}')`} }></div>
        </div>
    </div>
</div>
<div className="block-1x1-small">
    <div className="square background-color__fourth-color">
        <div className="square__content">
            <div className="content__inner__wrapper">
                <div>
                    <h2 className="heading__normal heading__normal__fix">
                        <span className="color__third-color">Gruppen- &</span><br />
                        <span className="color__third-color">Gesprächs</span><br />
                        <span className="color__third-color">Angebote</span>
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="block-1x1-small">
    <div className="square background-color__third-color">
        <span className="quote square__content flex-center color__first-color uppercase font__regular inner-text">Wer alt werden möchte,<br /> sollte früh damit beginnen!</span>
    </div>
</div>
<div className="block-1x1-small">
    <div className="square background-color__third-color">
        <div className="square__content">
        <div className="square__picture" style={ {backgroundImage: `url('${pic_konzentration}')`} }></div>
        </div>
    </div>
</div>
</section>

<section className="two-one-two">
<div className="block-1x2">
<div className="square background-color__third-color">
    <div className="square__content">
        <div className="content__inner__wrapper">
            <div>
                <h2 className="heading__normal heading__normal__fix">
                    <span className="color__first-color">Werk &</span><br />
                    <span className="color__fourth-color">Medien</span><br />
                    <span className="color__second-color">Raum</span>
                </h2>
            </div>
        </div>
    </div>
</div>
<div className="square background-color__first-color">
    <div className="square__content">
        <div className="content__inner__wrapper">
            <div>
                <h2 className="heading__normal heading__normal__fix">
                    <span className="color__third-color">Feinmotorik</span>
                </h2>
            </div>
        </div>
    </div>
</div>
</div>
<div className="block-1x1">
<div className="square background-color__fourth-color">
<div className="square__content">
    <div className="square__picture" style={ {backgroundImage: `url('${pic_werkraum}')`} }></div>
</div>
</div>
</div>
<div className="block-1x2">
    <div className="square background-color__second-color">
        <div className="square__content">
            <div className="content__inner__wrapper">
                <div>
                    <h2 className="heading__normal heading__normal__fix">
                        <span className="color__fourth-color">Kreativität</span>
                    </h2>
                </div>
            </div>
        </div>
    </div>
    <div className="square background-color__third-color">
        <div className="square__content">
            <div className="content__inner__wrapper">
                <div>
                    <h2 className="heading__normal heading__normal__fix">
                        <span className="color__first-color">Handwerk</span>
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<section className="one-one-one-one">
<div className="block-1x1-small">
    <div className="square background-color__fourth-color">
        <div className="square__content">
        <div className="square__picture" style={ {backgroundImage: `url('${pic_werkraum_2}')`} }></div>
        </div>
    </div>
</div>
<div className="block-1x1-small">
    <div className="square background-color__fourth-color">
        <div className="square__content">
            <div className="content__inner__wrapper">
                <div>
                    <h2 className="heading__normal heading__normal__fix">
                        <span className="color__third-color">Emotionale</span><br />
                        <span className="color__third-color">Entwicklung</span>
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="block-1x1-small">
    <div className="square background-color__third-color">
        <div className="square__content">
            <div className="content__inner__wrapper">
                <div>
                    <h2 className="heading__normal heading__normal__fix">
                        <span className="color__second-color">Stifthaltung</span>
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="block-1x1-small">
    <div className="square background-color__third-color">
        <div className="square__content">
        <div className="square__picture" style={ {backgroundImage: `url('${pic_werkraum_3}')`} }></div>
        </div>
    </div>
</div>
</section>

    </main>
);

export default Surgery;